*{
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

.app{
	display: flex;
	flex-direction: column;
	align-items: center;
}