.featured_projects_container{
    display: flex;
    flex-direction: column;
    align-items:center;
}

.featured_projects_title{
	transition: all 0.3s ease 0s;
	font-family: "Poppins", sans-serif;
	color: var(--text-color);
	display:flex;
	justify-content: center;
	width:100%;
	font-size: var(--heading-text);
	font-weight: 700;
	font-style: normal;
    margin-top:0;
    margin-bottom: 2rem;
    text-align: center;
}

.projects{
    display: flex;
    justify-content: center;
    align-items:center;
    justify-items:center;
    align-content:center;
    width:1500px;
    max-width: 80vw;
}

.projectHR{
	transition: all var(--transition-speed) ease 0s;
    width:1500px;
    max-width: 80vw;
    margin: 0 auto;
    border:0;
    height: 0.01rem;
    background:var(--text-color);
}

@media(max-width: 2500px){
    .projects{
        width: 60vw;
    }

    .projectHR{
        width: 60vw;
    }
}

@media(max-width: 1600px){
    .projects{
        width: calc(48vw + 192px);
    }
    
    .projectHR{
        width: calc(48vw + 192px);
    }
}