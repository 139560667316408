.theme {
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.theme__icon {
  transition: 0.3s;
}

.theme__icon,
.theme__toggle {
  z-index: 1;
}

.theme__icon,
.theme__icon-part {
  position: absolute;
}

.theme__icon {
  display: block;
  top: 0.375vw;
  left: 0.375vw;
  width: 0.75vw;
  height: 0.75vw;
}

.theme__icon-part {
  border-radius: 50%;
  box-shadow: 0.3vw -0.3vw 0 0.3vw hsl(0,0%,100%) inset;
  top: calc(50% - 0.29vw);
  left: calc(50% - 0.295vw);
  width: 0.6vw;
  height: 0.6vw;
  transition: box-shadow var(--transDur) ease-in-out,
	opacity var(--transDur) ease-in-out,
	transform var(--transDur) ease-in-out;
  transform: scale(0.5);
}

.theme__icon-part ~ .theme__icon-part {
  background-color: hsl(0,0%,100%);
  border-radius: 0.05vw;
  top: 50%;
  left: calc(50% - 0.04vw);
  transform: rotate(0deg) translateY(0.3vw);
  transform-origin: 50% 0;
  width: 0.06vw;
  height: 0.1vw;
}

.theme__icon-part:nth-child(3) {
  transform: rotate(45deg) translateY(0.3vw);
}

.theme__icon-part:nth-child(4) {
  transform: rotate(90deg) translateY(0.3vw);
}

.theme__icon-part:nth-child(5) {
  transform: rotate(135deg) translateY(0.3vw);
}

.theme__icon-part:nth-child(6) {
  transform: rotate(180deg) translateY(0.3vw);
}

.theme__icon-part:nth-child(7) {
  transform: rotate(225deg) translateY(0.3vw);
}

.theme__icon-part:nth-child(8) {
  transform: rotate(270deg) translateY(0.3vw);
}

.theme__icon-part:nth-child(9) {
  transform: rotate(315deg) translateY(0.3vw);
}

.theme__label,
.theme__toggle,
.theme-switch {
  position: relative;
}

.theme__toggle,
.theme__toggle:before {
  display: block;
}

.theme__toggle {
  background-color: var(--switch-color);
  border-radius: 25% / 50%;
  box-shadow: 0 0 0 0.125vw var(--primaryT);
  padding: 0.125vw;
  width: 3vw;
  height: 1.5vw;
  -webkit-appearance: none;
  appearance: none;
  transition: background-color var(--transDur) ease-in-out,
		box-shadow 0.15s ease-in-out,
		transform var(--transDur) ease-in-out;
}

.theme__toggle:before {
  background-color: var(--text-color);
  border-radius: 50%;
  content: "";
  width: 1.25vw;
  height: 1.25vw;
  transition: 0.3s;
}

.theme__toggle:focus {
  box-shadow: 0 0 0 0.125vw var(--primary);
  outline: transparent;
}

.theme__toggle:checked {
  background-color: var(--switch-color);
}

.theme__toggle:checked:before{
  transform: translateX(1.5vw);
}
.theme__toggle:checked ~ .theme__icon {
  transform: translateX(1.4vw);
}

.theme__toggle:checked:before {
  background-color: var(--text-color);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(1) {
  box-shadow: 0.1vw -0.1vw 0 0.1vw var(--bg-color) inset;
  transform: scale(1);
  top: 0.1vw;
  left: 0.175vw;
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part ~ .theme__icon-part {
  opacity: 0;
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(2) {
  transform: rotate(45deg) translateY(0.4vw);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(3) {
  transform: rotate(90deg) translateY(0.4vw);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(4) {
  transform: rotate(135deg) translateY(0.4vw);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(5) {
  transform: rotate(180deg) translateY(0.4vw);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(6) {
  transform: rotate(225deg) translateY(0.4vw);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(7) {
  transform: rotate(270deg) translateY(0.4vw);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(8) {
  transform: rotate(315deg) translateY(0.4vw);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(9) {
  transform: rotate(360deg) translateY(0.4vw);
}

.theme__toggle-wrap {
  margin: 0 0.375vw;
}

@supports selector(:focus-visible) {
  .theme__toggle:focus {
    box-shadow: 0 0 0 0.125vw var(--primaryT);
  }

  .theme__toggle:focus-visible {
    box-shadow: 0 0 0 0.125vw var(--primary);
  }
}