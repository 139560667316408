.project_page_container{
	transition: all var(--transition-speed) ease 0s;
    background: var(--bg-color);
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.project_header{
	transition: all var(--transition-speed) ease 0s;
    font-family: "Poppins", sans-serif;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
}

.project_title{
    font-size: var(--heading-text);
    font-weight: 700;
    text-align:center;
    padding-top:20vh;
    animation: 0.3s 0.1s bringUpFadeTag;
    animation-fill-mode: forwards;
    opacity: 0%;
}

.project_tags{
    display:flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 30vw;
    font-size: var(--subcontent-text);
    padding-top: 2rem;
    white-space: nowrap;
    animation: 0.3s 0.2s bringUpFadeTag;
    animation-fill-mode: forwards;
    opacity: 0%;
}

.project_content{
	transition: all var(--transition-speed) ease 0s;
    font-family: "Poppins", sans-serif;
    font-size: var(--subcontent-text);
    color: var(--text-color);
    padding-top: 3rem;
    width: 80vw;
}

.project_content a{
    color: inherit;
}

.tagItem:hover{
    border: 0.1px solid var(--text-color)
}

.project_timeline{
    font-size: var(--subcontent-text);
    padding-top: 0.6rem;
    animation: 0.3s 0.2s bringUpFadeTag;
    animation-fill-mode: forwards;
    opacity: 0%;
}

.project_page_thumbnail{
    width: 50vw;
    animation: 0.3s 0.3s bringUpFadeTag;
    animation-fill-mode: forwards;
    opacity: 0%;
}
