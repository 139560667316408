.nav_links li, .nav_links a{
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-size:var(--header-text);
	color: var(--text-color);
	text-decoration: none;
}

#navbar{
	background: var(--bg-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding:2vw 10%;
	position: fixed;
	box-shadow: none;
	top:0;
	width:100%;
	opacity: 1;
	z-index: 1;
	transition: all var(--transition-speed) ease 0s;
	max-width: 2560px;
}

#navbar.notNavTop{
	box-shadow: 0 0.3px 1px var(--header-line-color);
	background: var(--header-color);
	opacity: 0.99;
}

.logo{
	cursor: pointer;
	font-family: "Playfair Display", serif;
	font-weight: 400;
	letter-spacing: 0.375rem;
	font-size: var(--header-text)
}

.logo a{
	text-decoration: none;
	color: var(--text-color);
}

.nav_links{
	list-style: none;
	cursor: pointer;
}

.nav_links li{
	display:inline-block;
	padding: 0px 1vw;
}

.nav_link{
	transition: all var(--transition-speed) ease 0s;
}

.nav_link:hover{
	color:var(--text-hover-color);
}

.header_svg{
	width: 50px;
}

@media (max-width: 1600px){
	.header_svg{
		width: 3.125vw;
	}
}

.header_svg path{
	transition: all var(--transition-speed) ease 0s;
	fill:var(--text-color);
}

.header_svg:hover path{
	fill:var(--text-hover-color);
}

.logo h4 a{
	transition: all var(--transition-speed) ease 0s;
}

.logo h4 a:hover{
	transition: color 0.3s ease 0s;
	color:var(--text-hover-color);
}

.fade_in{
	opacity: 0;
    -moz-transition: opacity 1s;
    -webkit-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
}

body.loaded .fade_in{
	opacity:1;
}

.bring_up_upper{
	opacity: 0;
	-moz-transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    transform: translateY(60px);
}

.nav_icons{
	list-style: none;
}

.nav_icons li{
	display:inline-block;
}

.icon{
	padding: 0px 0.8vw;
}