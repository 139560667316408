/* The browser window */
.code_block_container {
    border: 8px solid var(--text-highlight-color);
    border-radius: 16px;
    width:100%;
}
  
  /* Container for columns and the top "toolbar" */
.row {
    padding: 5px;
    padding-left:0.3rem;
    background: var(--text-highlight-color);
}
  
.left {
    width: 15%;
}
  
  /* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}
  
  /* Three dots */
.dot {
    margin-right: 0.25vw;
    height: 0.6vw;
    width: 0.6vw;
    border-radius: 50%;
    display: inline-block;
}

.d1{
    background-color: #ed594a;
}

.d2{
    background-color: #fdd800;
}

.d3{
    background-color: #5ac05a;
}