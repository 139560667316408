.welcome_container{
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-height: 25vw;
	justify-content: center;
}

.welcome{
	transition: all 0.3s ease 0s;
	display: flex;
	flex-direction: column;
    align-items: center;
    width:100vw;
	max-width: 2560px;
}

.name_1, .name_2{
	transition: all 0.3s ease 0s;
	font-family: "Poppins", sans-serif;
	color: var(--text-color);
	display:flex;
	align-items: center;
	justify-content: center;
	width:100vw;
}

.name_1{
	font-size: var(--title-text);
	font-weight: 500;
	font-style: normal;
	opacity:0%;
	animation: 0.6s 0.1s bringUpFade;
	animation-fill-mode: forwards;
	max-width: 80vw;
}

.name_2{
	padding-top:0.625rem;
	font-weight:400;
	text-align: center;
	width:1536px;
	margin-left:auto;
	margin-right: auto;
	font-size: var(--subtitle-text);
	opacity:0%;
	animation: 0.6s 0.7s bringUpFade;
	animation-fill-mode: forwards;
}

@media (max-width: 2000px){
	.name_2{
		width: 80vw;
	}
}

@keyframes bringUpFade{
	from{
		opacity: 0%;
		transform: translateY(40px);
	}
	to{
		opacity: 100%;
		transform: translateY(0px);
	}
}

/* @media(max-height: 500px){
	.welcome{
		height:100vh;
	}
} */