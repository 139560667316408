.about_page{
    transition: all var(--transition-speed) 0s ease;
    background-color: var(--bg-color);
    width:100%;
    display:flex;
    justify-content: center;
}

.about_page_container{
    transition: all var(--transition-speed) 0s ease;
    background-color: var(--bg-color);
    padding-top: 20vh;
    display:flex;
    flex-direction: row-reverse;
    max-width: 1700px;
    width: 80vw;
    justify-content: space-between;
    min-height: 85vh;
    height: 100%;
}

.about_page_text{
    transition: all var(--transition-speed) 0s ease;
    font-family: 'Poppins', sans-serif;
    color: var(--text-color);
    width: 50%;
}

.about_page_text a{
    transition: all var(--transition-speed) 0s ease;
    text-decoration: underline;
}

.about_page_text a:link{
    color: var(--text-color);
}

.about_page_text a:visited{
    color: var(--text-color);
}

.about_page_title{
    font-size: var(--heading-text);
    font-weight: 700;
    animation: 0.3s 0.1s bringUpFadeTag;
    animation-fill-mode: forwards;
    opacity: 0%;
}

.about_page_subtitle{
    font-size: var(--subheading-text);
    padding-bottom: 20px;
    animation: 0.3s 0.15s bringUpFadeTag;
    animation-fill-mode: forwards;
    opacity: 0%;
}

.about_page_p{
    font-size: var(--code-text);
    animation: 0.3s 0.2s bringUpFadeTag;
    animation-fill-mode: forwards;
    opacity: 0%;
}

.about_page_image{
    display:flex;
    flex-direction: row;
    align-items: center;
}

.portfolio_image{
    border-radius: 500px;
    width: 500px;
    height: 500px;
}

@media(max-width:1800px){
    .portfolio_image{
        width: 27.777vw;
        height: 27.777vw;
    }
}

@media(max-width:1200px){
    .about_page_container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 0vh;
    }
    
    .about_page_text{
        width: 100%;
    }

    .about_page_title{
        text-align: center;
        font-size: 10vw;
    }

    .about_page_subtitle{
        font-size: 4vw;
    }

    .about_page_p{
        font-size: 2vw;
    }

    .about_page_image{
        margin-top: 15vw;
        margin-bottom: 50px;
    }
}

@media(max-width: 800px){
    .about_page_subtitle{
        font-size: 32px;
    }

    .about_page_p{
        font-size: 16px;
    }
}

@media(max-width: 600px){
    .about_page_title{
        font-size: 60px;
    }
}