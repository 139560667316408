:root{
    --bg-color: #ffffff;
    --text-color: #282c34;
    --text-highlight-color: #e8e8e8;
    --switch-color: #496B6F;
    --header-color: #ffffff;
    --header-line-color: rgba(6,8,8,0.5);
    --text-hover-color: #b8b8b8;
    --project-card-highlight-color: #f5f5f5;
    --project-card-outline-color: #ebebeb;
    --transition-speed: 0.3s;
    --title-text: 300px;
    --subtitle-text: 40px;
    --header-text: 40px;
    --heading-text: 150px;
    --subheading-text: 50px;
    --content-text: 40px;
    --subcontent-text: 25px;
    --code-text: 20px;
}

@media (max-width: 2400px){
    :root{
        --title-text: 12.5vw;
        --heading-text: 6.25vw;
    }
}

@media (max-width: 2000px){
    :root{
        --subtitle-text: 2vw;
    }
}

@media (max-width: 1600px){
    :root{
        --header-text: 2.5vw;
        --subcontent-text: 1.5625vw;
        --subheading-text: 3.125vw;
        --code-text: 1.25vw;
    }
}

@media (max-width: 1500px){
    :root{
        --subtitle-text: 30px;
    }
}

@media (max-width:1200px){
    :root{
        --subtitle-text: 2.5vw;
    }
}

.dark-mode{
    --bg-color: #282c34;
    --text-color: #ffffff;
    /* --text-highlight-color: #182425; */
    --text-highlight-color: #121417;
    --switch-color: #496B6F;
    --header-color: #282c34;
    --header-line-color: rgba(150, 150, 150, 0.5);
    --text-hover-color: #626B7F;
    --project-card-highlight-color: #31363f;
    --project-card-outline-color: #646c7c;
}

body{
	transition: all var(--transition-speed) ease 0s;
    background-color: var(--bg-color);
}