.project_card_svg path{
	transition: all var(--transition-speed) ease 0s;
	fill:var(--text-color);
}

.project_card_svg{
	transition: all 0.2s ease 0s;
    position:relative;
    width: 2.5vw;
    max-width: 64px;
    transform: translateX(0%) translateY(0%);
}

.clickableCard{
    width:100%;
	transition: all var(--transition-speed) ease 0s;
    text-decoration:none;
    cursor:pointer;
    padding:0.4rem 0.8rem 0.8rem 0.8rem;
    border-radius:5px;
    border-top: 0.5px solid var(--bg-color);
}

.clickableCard:hover .project_card_svg{
    transform: translateX(20%) translateY(-20%);
}
.clickableCard:hover{
    background-color: var(--project-card-highlight-color);
    border-top: 0.5px solid var(--project-card-outline-color);
}
.clickableCard:hover .tagItem{
    border: 0.1px solid var(--project-card-highlight-color);
}
.clickableCard:hover .tagItem:hover{
    border: 0.1px solid var(--text-color);
}

.projectCardContainer{
    display: flex;
    flex-direction:column;
    align-items: center;
    padding-top: 2rem;
    width:100%;
}

.projectCard{
	transition: all var(--transition-speed) ease 0s;
	font-family: "Poppins", sans-serif;
	color: var(--text-color);
	display:flex;
	font-weight: 500;
	font-style: normal;
    justify-content: space-between;
}

.projectCardTitle{
    display:flex;
    flex-direction:column;
    width:50%;
    font-weight:700;
}

.cardMain{
    display:flex;
    flex-direction: row;
    align-items:flex-end;
    margin-bottom: 0.2rem;
}

.cardTitle{
    font-size: var(--subheading-text);
}

.outLink{
    padding-left:0.2rem;
}

.tags{
    font-size: var(--subcontent-text);
    font-weight: 300;
    display:flex;
    flex-direction: row;
}

.tagItem{
    text-decoration:none;
    color: var(--text-color);
	transition: all var(--transition-speed) ease 0s;
    background: var(--text-highlight-color);
    border-radius: 10px;
    padding: 0 8px;
    margin-right: 8px;
    border: 0.1px solid var(--bg-color);
}

.projectCardDescription{
    font-size: var(--subcontent-text);
    font-weight: 300;
    width:50%;
    padding-top:0.5rem;
}

.projectThumbnail{
    margin-top: 1.5rem;
    width:100%;
}

.codeBlock{
    font-family: "Fira Code";
    font-size: var(--code-text);
    background: var(--text-highlight-color);
    border: 2px solid var(--text-highlight-color);
    border-radius: 8px;
}