footer{
	width:100%;
	background:var(--bg-color);
	scroll-snap-align: start;
	transition: all var(--transition-speed) ease 0s;
	padding-top: 3rem;
	max-width:2560px;
}

#footer_icons{
	display:flex;
	align-items: center;
	justify-content: center;
	padding-bottom:0.75rem;
}

#footer_text{
	transition: all var(--transition-speed) ease 0s;
	font-family: "Poppins", sans-serif;
	font-size: var(--subcontent-text);
	color: var(--text-color);
	display:flex;
	align-content: center;
	justify-content: center;
	padding-bottom: 30px;
}